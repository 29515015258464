import * as React from "react";
import "../scss/style.scss";

const ErrorPage = () => {
  React.useEffect(() => {
    let printWindowDimensions = function () {
      document.querySelector(".window-height").innerText = window.innerHeight;
      document.querySelector(".window-width").innerText = window.innerWidth;
    };

    printWindowDimensions();

    window.addEventListener("resize", printWindowDimensions);

    document.querySelector(".user-agent").innerText = navigator.userAgent;
  });

  return (
    <div className="error-page">
      <canvas id="canvas-front"></canvas>
      <canvas id="canvas-back"></canvas>

      <div className="heading">
        Status{" "}
        <span>
          404 <span className="blink">[Page not Found]</span>
        </span>
      </div>

      <div className="info-panel top-left">This request has been logged</div>

      <div className="info-panel top-right">
        XY <span className="window-width"></span>&times;
        <span className="window-height"></span>
        <br />
      </div>

      <div className="info-panel bottom">
        Agent <span className="user-agent"></span>
      </div>
    </div>
  );
};

export default ErrorPage;
